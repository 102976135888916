import logo from './logo.gif';
import './App.css';
import styled from "styled-components";

function App() {
	const Button = styled.button`
  background-color: #09064f;
  color: white;
  font-size: 20px;
  padding: 10px 50px;
  border-radius: 15px;
  margin: 10px 5px;
  cursor: pointer;
`;

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
	  <div>
	  <p>
		Content Goes Here...
	  </p>
	  </div>
      <div>
		  <p>
			  <Button> Contract </Button>
			  <Button> Telegram </Button>
			  <Button> Chart </Button>
			  <Button> Telegram </Button>
		  </p>
	  </div>
	  
    </div>
	
  );
}

export default App;
